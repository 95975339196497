<template>
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <v-card-title class="">
        <span>{{ title }}</span>
      </v-card-title>
    </v-card>
    <!-- 全部消息 -->
    <div>
      <v-data-table style="" v-model="selected" :headers="header" :items="messages" show-select :search="messageSearch"
        :item-class="v => (v.readed ? '' : 'font-weight-black')">
        <template v-slot:top>
          <v-row align="center">
            <v-col cols="12" sm="4">
              <v-btn color="primary" @click="readAll()" class="mr-1">
                全部已读
              </v-btn>
              <v-btn color="primary" @click="deleteMsg()">
                删除选中
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" offset="2">
              <v-text-field v-model="messageSearch" solo dense class="" append-icon="mdi-magnify" label="搜索" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <div>暂无数据</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="info(item)" v-on="on" v-bind="attrs">
                mdi-eye
              </v-icon>
            </template>
            <span>详情</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="del(item.id)" v-on="on" v-bind="attrs">
                mdi-close
              </v-icon>
            </template>
            <span>删除</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.createTime`]="{ item }">
          {{ paresTime(item.createTime) }}
        </template>
      </v-data-table>
      <!-- 消息详情 -->
      <v-dialog v-model="infoDialog" max-width="600px">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="标题" v-model="infoForm.title" disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="时间" v-model="infoForm.time" disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea name="input-7-1" filled label="内容" auto-grow disabled :value="infoForm.info">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="infoDialog = false">
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
// let vm;
import axios from "axios"; //基于Promise的HTTP客户端
// //import qs from "qs"
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.104:64011/";
// let version = "";
export default {
  data () {
    return {
      title: "系统消息",
      header: [
        {
          text: "标题",
          align: "start",
          sortable: false,
          value: "title"
        },
        { text: "创建时间", sortable: false, value: "createTime" },
        { text: "发送人", sortable: false, value: "sender" },
        // { text: "认证状态", sortable: false, value: "applyStatus" },
        { text: "操作", sortable: false, value: "actions" }
      ],
      infoForm: {
        title: "",
        info: "",
        time: ""
      },
      selected: [],
      messages: [], //消息列表
      messageSearch: "", //消息搜索条件
      showSysMsgRecord: true,
      infoDialog: false
    };
  },
  created () {
    this.getAllMessages();
  },
  props: ["role", "username"],
  methods: {
    /**
     * 获得订单浏览记录
     */
    getAllMessages () {
      axios.get(`${commonUrl}listAllMsg${version}`).then(res => {
        this.messages = res.data.result;
      });
    },
    /**
     * 格式化时间戳
     */
    paresTime (time) {
      return new Date(time).format("yyyy-MM-dd hh:mm:ss");
    },
    /**
     * 全部已读
     */
    readAll () {
      axios.get(`${commonUrl}readAllMsg${version}`).then(res => {
        console.log(res);
        this.getAllMessages();
      });
    },
    /**
     * 删除一条消息
     */
    del (id) {
      this.$dialog
        .confirm({
          text: "是否删除此消息",
          title: "删除消息",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            let idList = new Array();
            idList.push(id);
            let param = {
              id: idList
            };
            axios
              .post(`${commonUrl}deleteMsg${version}`, param)
              .then(res => {
                if (res.data.code == 200) {
                  this.$emit("tips", "删除成功", "green");
                  console.log(res);
                  this.getAllMessages();
                } else if (res.data.message != null) {
                  this.$emit("tips", res.data.message, "red");
                } else {
                  this.$emit("tips", "删除失败", "red");
                }
              })
              .catch(e => {
                console.log(e);
                this.$emit("tips", "删除失败", "red");
              });
          }
        });
    },
    /**
     * 批量删除
     */
    deleteMsg () {
      if (this.selected.length == 0) {
        this.$emit("tips", "无选中数据", "red");
        return;
      }
      this.$dialog
        .confirm({
          text: "是否删除选中消息",
          title: "删除消息",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            let idList = new Array();
            for (let item of this.selected) {
              idList.push(item.id);
            }
            let param = {
              id: idList
            };
            axios
              .post(`${commonUrl}deleteMsg${version}`, param)
              .then(res => {
                console.log(res);
                if (res.data.code == 200) {
                  this.$emit("tips", "删除成功", "green");
                  this.getAllMessages();
                } else if (res.data.message != null) {
                  this.$emit("tips", res.data.message, "red");
                } else {
                  this.$emit("tips", "删除失败", "red");
                }
              })
              .catch(e => {
                console.log(e);
                this.$emit("tips", "删除失败", "red");
              });
          }
        });
    },
    /**
     * 消息详情
     */
    info (item) {
      console.log(item);
      item.readed = true;
      let param = new FormData();
      param.append("id", item.id);
      axios.post(`${commonUrl}getMsgInfo${version}`, param).then(res => {
        console.log(res);
        this.infoForm = {
          title: res.data.result.title,
          info: res.data.result.info,
          time: this.paresTime(res.data.result.createTime)
        };
      });
      this.infoDialog = true;
    }
  }
};
</script>
